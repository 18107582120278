/* ========================================================================
 * Script to manage dates & calendar in single event pages.
 * ======================================================================== */

(function ($) {

    var geocoder;
    function init() {

        states = ["vic","qld","nsw","act","nt","wa","sa","tas"];
        states.forEach(function(v) {
            if(window.location.pathname == '/ahf/'+v+'/') {
                $("#region-search").val(v.toUpperCase());
            }
        });

        geocoder = new google.maps.Geocoder();

        if ($('#region-search').length > 0) {

            var $url = 'http://' + window.location.hostname + '/explore/';
            window.submitAHF = function () {
                while ($('[name=content]').length > 1) {
                    $('[name=content]').first().remove();
                }
                if ($('#region-search').val() === '') {
                    $('#region-lat').val('');
                    $('#region-lng').val('');
                }
                if($('#region-search').val() !== '' &&
                    ($('#region-lat').val() === '' ||
                    $('#region-lng').val() === '')) {

                    var place = autocomplete.getPlace();
                    if (place == undefined || !place.geometry) {
                        var query = place == undefined ? $('#region-search').val() : place.name;
                        ac = new google.maps.places.AutocompleteService();
                        ac.getPlacePredictions({
                            types: ['(regions)'],
                            componentRestrictions: {'country': ['au']},
                            input: query
                        }, function (predections) {
                            var places = new google.maps.places.PlacesService($('.field-four')[0])
                            places.getDetails({placeId: predections[0].place_id}, function () {
                                $('#region-lat').val(arguments[0].geometry.location.lat());
                                $('#region-lng').val(arguments[0].geometry.location.lng());
                                submitAHF();

                            });
                        });
                        return;
                    }
                    $('#region-lat').val(place.geometry.location.lat());
                    $('#region-lng').val(place.geometry.location.lng());
                    submitAHF();

                    return;
                }
                var goto = $url +
                    "?" + $('form#ahf-filters').serialize().replace(/\+/g, '%20') +
                    "&" + $('form#filters').serialize().replace(/\+/g, '%20');
                if( !goto.match(/content=ahf_event/)) {
                    goto += '&content=ahf_event'
                    goto = goto.replace('&&','&');
                }
                window.location = goto;
            };
            console.log('[1]');
            $("#ahf_datepicker").datepicker({
                dateFormat: 'dd/mm/yy',
                beforeShow: function (input, inst) {
                    $('#ui-datepicker-div').addClass('cal-dark');
                },
                firstDay: 1,
                dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                minDate: new Date(2024, 03, 1),
                maxDate: new Date(2024, 04, 31)
            });
            $('#ahf_cal_icon').click(function () {
                $("#ahf_datepicker").focus();
            });


            $('#ahf-filters #keyword-search').on('keyup', function (e) {
                if (e.which == 13) {
                    submitAHF()
                }
            });
            $('#ahfSubmit,#filterSubmit').on('click', function(e) {
                e.preventDefault();
                submitAHF();
            });


            $('form#ahf-filters').on('submit', function (e) {
                e.preventDefault();
            });

            $('#region-search').on('keyup', function (event) {
                if (event.which == 13) {
                    event.preventDefault();
                    if($('#region-search').val() === "") {
                        submitAHF();
                        return;
                    }

                    setTimeout(function () {
                        var place = autocomplete.getPlace();
                        if (place == undefined || !place.geometry) {
                            var query = place == undefined ? $('#region-search').val() : place.name;
                            ac = new google.maps.places.AutocompleteService();
                            ac.getPlacePredictions({
                                types: ['(regions)'],
                                componentRestrictions: {'country': ['au']},
                                input: query
                            }, function (predections) {
                                var places = new google.maps.places.PlacesService($('.field-four')[0])
                                places.getDetails({placeId: predections[0].place_id}, function () {
                                    // console.log('First place', arguments[0])
                                    $('#region-lat').val(arguments[0].geometry.location.lat());
                                    $('#region-lng').val(arguments[0].geometry.location.lng());
                                    submitAHF();

                                });
                            });
                            // console.log("No details available for input: '" + query + "'");
                            return;
                        }
                        $('#region-lat').val(place.geometry.location.lat());
                        $('#region-lng').val(place.geometry.location.lng());
                        submitAHF();
                    }, 300);
                }

            })

            var input = $('#region-search')[0];
            var autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.setTypes(['(regions)']);
            autocomplete.setComponentRestrictions(
                {'country': ['au']});
            autocomplete.addListener('place_changed', function (e) {
                var place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.log("No details available for input: '" + place.name + "'");
                    return;
                }
                $('#region-lat').val(place.geometry.location.lat());
                $('#region-lng').val(place.geometry.location.lng());
            });

            $('#region-results').on('click', 'li', function () {
                $('#region-search').val($(this).text()).trigger('keyup');
            });
        }

    }

    $(document).ready(init)
    //google.maps.event.addDomListener(window, "load", init);

    if ($('#location-btn').length > 0) {
        if (navigator.geolocation) {
            $('#location-btn').removeClass('hidden');
            var optn = {
                enableHighAccuracy: true,
                timeout: Infinity,
                maximumAge: 0
            };

            function showError(error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        alert("User denied the request for Geolocation.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        alert("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        alert("The request to get user location timed out.");
                        break;
                    case error.UNKNOWN_ERROR:
                        alert("An unknown error occurred.");
                        break;
                }
            }

            function usePosition(position) {
                var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                geocoder.geocode({'location': latlng}, function (results, status) {
                    if (status === 'OK') {
                        if (results[0]) {
                            var $address = results[0].address_components;
                            $.each($address, function (i, address_component) {
                                if (address_component.types[0] == "locality") {
                                    // console.log("town:"+address_component.long_name);
                                    var itemLocality = address_component.long_name;
                                    $("#region-search").val(itemLocality);
                                    $("#region-lat").val(position.coords.latitude);
                                    $("#region-lng").val(position.coords.longitude);
                                }
                            })
                        } else {
                            alert('We could not locate your position');
                        }
                    } else {
                        alert('Could not locate your position due to: ' + status);
                    }
                });
            }

            $('#location-btn').on('click', function (e) {
                navigator.geolocation.getCurrentPosition(usePosition, showError, optn);
                e.preventDefault();
            })

        }
    }


})(jQuery);
